import React, { lazy, Suspense, useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import img1 from '../../../assets/images/banner1.jpg';
import img2 from '../../../assets/images/banner5.jpg';
import img3 from '../../../assets/images/banner6.jpg';
import { RouteNames } from '../../../includes/route-names';

const SearchBar = lazy(() => import('../../../reuseables/SearchBar'));

const Banner = (props) => {
	const [ isComponentLoaded, setIsComponentLoaded ] = useState( false );
	
	useEffect(()=>{
        setIsComponentLoaded( true );
	},[]);
	
	//console.log( img1 );

    return (
        <div className={`banner ${props.is_home ? 'homebanner':''} `}>
			
			{
				isComponentLoaded &&
				<OwlCarousel 
					className="banner_slider owl-theme"
					items={1}  
					lazyLoad={true}
					autoplay={true}
					loop  
					nav  
					margin={0} >  

					<div className="item">
						<Link to={RouteNames.buy}>
						<img src={img1} alt=""/>
						<div className="bannercontent">
							<div>
								<h2>fun without <span>fuel</span></h2>
								<p>Peddling to a green future</p>
							</div>
						</div>
						</Link>
					</div> 
					{/* <div className="item">
						<img src={require('../../../assets/images/banner2.jpg')} alt=""/>
						<div className="bannercontent">
							<div>
								<h2>Collaborate & <span>CarryOn</span></h2>
								<p>Explore Bike Trails  with fellow enthusiasts and get recommendations through AI enabled engine</p>
							</div>
						</div>
					</div> */}
					{/* <div className="item">
						<img src={require('../../../assets/images/banner4.jpg')} alt=""/>
						<div className="bannercontent">
							<div>
								<h2>Suggestions <span>please</span></h2>
								<p>Feedback on Trails and Tours And Expert Advise for Casual Riders</p>
							</div>
						</div>
					</div>   */}
					<div className="item">
						<Link to={RouteNames.services}>
						<img src={img2} alt=""/>
						<div className="bannercontent">
							<div>
								<h2>nearest <span>repair buddy</span></h2>
								<p>Find the professional mechanic or bike repair shop nearest to breakdown point</p>
							</div>
						</div>
						</Link>
					</div> 
					<div className="item">
					<Link to={RouteNames.buy}>
	    			<img src={img3} alt=""/>
	    			<div className="bannercontent">
	    				<div>
				    		<h2>choose the <span>right bike</span></h2>
				    		<p>Compare and get the best deals. Pick the best bike for your needs. If not satisfied get expert tips, insights and trends</p>
				    	</div>
			    	</div>
					</Link>
	    		</div>

				</OwlCarousel> 
			}
			<Suspense fallback={<div/>}>
				<SearchBar is_home={props.is_home}/>
			</Suspense>
	    	         	 
		</div> 
    )
}

export default Banner
